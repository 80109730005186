<template>
  <div class="login-container">
    <div class="login-success" v-if="success">
      <img src="/static/success.png" />
      <h2>{{$t('forgot.resetSuccess')}}</h2>
      <van-button block round type="warning" to="/login">{{$t('forgot.backLogin')}}</van-button>
    </div>
    <div class="login-box">
      <!-- <div class="login-lang">
        <van-button plain type="warning" size="mini" hairline @click="changeLocale">{{ $t('locale') }}</van-button>
      </div> -->
      <div class="login-title">{{$t('forgot.resetTitle')}}</div>
      <p class="login-tip">
      </p>
      <div class="login-item">
        <label>{{$t('forgot.newPassword')}}</label>
        <input type="password" :placeholder="$t('forgot.newPassword')" v-model="password" />
      </div>
      <div class="login-item">
        <label>{{$t('regf.password2')}}</label>
        <input type="password" :placeholder="$t('regf.password2Tip')" v-model="old_password" />
      </div>
      <div class="login-btn">
        <van-button type="warning" block @click="doLogin" :loading="loading" :loading-text="$t('reg.loading')">{{$t('forgot.resetTitle')}}</van-button>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script type="text/javascript">
import user from '@/api/user'
import { mapState } from 'vuex'
import Footer from '@/components/Footer'
export default {
  components: { Footer },
  data() {
    return {
      phone: '',
      auth_code: '',
      nickname: '',
      email: '',
      password: '',
      old_password: '',
      year: (new Date()).getFullYear(),
      loading: false,
      time: 0,
      success: false,
    }
  },
  computed: {
    ...mapState(['locale'])
  },
  created() {
    const { token } = this.$route.query
    if (!token) {
      this.$toast('重置信息不存在')
      this.$router.replace('/login')
      return false
    }
    this.auth_code = token
  },
  methods: {
    changeLocale() {
      this.$i18n.locale = this.locale === 'zh-CN' ? 'en-US' : 'zh-CN'
      this.$store.dispatch('updateLocale', this.$i18n.locale)
    },
    doLogin() {
      if (this.loading) return false
      if (!this.password || this.password.length < 6) {
        this.$toast('请输入6位及以上密码')
        return false
      }
      if (this.password !== this.old_password) {
        this.$toast('两次输入密码不一致')
        return false
      }
      this.loading = true
      user.resetPassword(this.auth_code, {
        password: this.password,
        old_password: this.old_password,
      })
      .then(() => {
        this.success = true
        this.loading = false
      })
      .catch(err => {
        this.$toast(err || '重置失败')
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  &-container {
    background-image: url(/static/login_bg.png);
    background-size: cover 230px;
    background-repeat: repeat-x;
    padding: 93px 15px 30px;
    background-color: #F0F4F6;
    min-height: 100vh;
    color: #333;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  &-box {
    background: #fff;
    border-radius: 12px;
    padding: 50px 25px 70px;
    position: relative;
  }
  &-title {
    font-size: 28px;
    line-height: 40px;
    font-weight: 500;
  }
  &-tip {
    line-height: 20px;
    margin-top: 4px;
    a {
      color: @orange;
    }
  }
  &-item {
    position: relative;
    margin-bottom: 22px;
    label {
      font-size: 12px;
      color: #999;
      display: block;
    }
    input {
      height: 32px;
      padding: 3px 0;
      line-height: 20px;
      font-size: 14px;
      color: #333;
      border: 0 none;
      outline: none;
      width: 100%;
      border-bottom: 1px solid #E3E3E3;
      border-radius: 0;
      &:focus {
        outline: none;
        border-bottom: 1px solid #ED6900;
      }
    }
    .item-tip {
      position: absolute;
      right: 5px;
      font-size: 14px;
      bottom: 6px;
    }
  } 
  &-btn {
    margin-top: 30px;
    &-tip {
      margin-top: 6px;
      text-align: right;
      a {
        color: @orange;
      }
    }
    .van-button {
      border-radius: 12px;
    }
  }
  &-ft {
    color: #B8B8B8;
    text-align: center;
    margin-top: 30px;
  }
  &-auth_code {
    &-btn {
      float: right;
      color: @orange;
    }
  }
  &-success {
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 1;
    padding: 100px 50px;
    text-align: center;
    img {
      width: 48px;
    }
    h2 {
      font-size: 26px;
      color: #1A1A1A;
      margin-top: 14px;
      margin-bottom: 100px;
    }
  }
  &-lang {
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
</style>
